import api, { ApiFetchParams, ApiResponse } from './api';

export interface Fault {
  description: string;
}

export interface Part {
  name: string;
  confidence?: number;
}

export interface Prediction {
  rootCause: string;
  confidence: number;
  predictedParts: Part[];
}

export interface PredictionList {
  predictions: Prediction[];
}

export interface PredictionData extends PredictionList {
  faults: Fault[];
}

export type PredictionResponse = ApiResponse<PredictionData>;

export interface RealTimePredictionsParams extends ApiFetchParams {
  symptom: string;
  jobId: string;
}

export interface RealTimePredictionsGetParams
  extends RealTimePredictionsParams {
  equipmentId: string;
}

export const fetchRealTimePredictions = async ({
  symptom,
  equipmentId,
  jobId,
  token,
  signal,
}: RealTimePredictionsGetParams): Promise<PredictionData> => {
  const predictionResponse = await api.get<PredictionResponse>({
    token,
    path: '/fault/api/v1/predictions',
    queryParams: {
      defaultFaultDescription: symptom,
      defaultEquipmentId: equipmentId,
      jobIds: jobId,
      realTime: true,
    },
    config: { signal },
    retry: 10,
  });

  return predictionResponse.data;
};

export const regenerateRealTimePredictions = async ({
  symptom,
  jobId,
  token,
  signal,
}: RealTimePredictionsParams): Promise<PredictionData> => {
  const predictionResponse = await api.post<PredictionResponse>({
    token,
    path: '/fault/api/v1/predictions',
    queryParams: {
      jobId,
      faultDescription: symptom,
    },
    config: { signal },
    retry: 10,
  });

  return predictionResponse.data;
};
