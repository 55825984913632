import { AsyncThunkAction } from '@reduxjs/toolkit';
import { AppThunkConfig } from '../store';

export const isDefined = <T>(value: T | undefined | null): value is T => {
  return value !== undefined && value !== null;
};

export enum FetchingStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error',
}

export type ApiCall<R, A> =
  | ReturnType<AsyncThunkAction<R, A, AppThunkConfig>>
  | undefined;
