import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../store/utils/hooks';
import {
  getPredictions,
  regeneratePredictions,
} from '../../../store/slices/predictions.slice';
import { ApiCall, FetchingStatus, isDefined } from '../../../store/utils';
import { useMockServerActive } from '../../../store/slices/mockServer.slice';
import { PredictionList } from '../../../integration/realTimePredictions.api';
import styles from './SymptomSearch.module.scss';

const SymptomSearch: React.FC = () => {
  const dispatch = useAppDispatch();
  const { subject, initialPredictionsStatus } = useAppSelector(
    (state) => state.predictions,
  );
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const serviceCallKeyValues = useAppSelector(
    (state) => state.serviceCall.keyValues,
  );
  const isMockServerActive = useMockServerActive();

  const [symptom, setSymptom] = useState('');
  useEffect(() => {
    if (subject !== null) {
      setSymptom(subject);
    }
  }, [subject]);

  const [prevRequest, setPrevRequest] =
    useState<ApiCall<PredictionList, string | void>>();

  useEffect(() => {
    if (!isAuthenticated || serviceCallKeyValues === null) {
      return;
    }
    if (isDefined(prevRequest)) {
      prevRequest.abort();
    }
    const action = dispatch(getPredictions());
    setPrevRequest(action);
  }, [dispatch, serviceCallKeyValues, isAuthenticated, isMockServerActive]);

  const handleRegenPredictions = () => {
    if (symptom === '') {
      return;
    }
    if (isDefined(prevRequest)) {
      prevRequest.abort();
    }
    const action = dispatch(regeneratePredictions(symptom));
    setPrevRequest(action);
  };

  return (
    <div className={styles.container}>
      <textarea
        data-testid="symptom-search"
        disabled={initialPredictionsStatus !== FetchingStatus.SUCCESS}
        className={styles.searchField}
        value={symptom}
        onChange={(event) => setSymptom(event.target.value)}
      />
      <button
        className={styles.btn}
        onClick={handleRegenPredictions}
        disabled={initialPredictionsStatus !== FetchingStatus.SUCCESS}
      >
        Fetch Predictions
      </button>
    </div>
  );
};

export default SymptomSearch;
