import { configureStore } from '@reduxjs/toolkit';

import {
  SERVICE_CALL_KEY,
  serviceCallReducer,
} from './slices/serviceCall.slice';
import {
  PREDICTIONS_KEY,
  predictionsReducer,
} from './slices/predictions.slice';
import { AUTH_KEY, authReducer } from './slices/auth.slice';
import { MOCK_SERVER_KEY, mockServerReducer } from './slices/mockServer.slice';

const reducers = {
  [SERVICE_CALL_KEY]: serviceCallReducer,
  [PREDICTIONS_KEY]: predictionsReducer,
  [AUTH_KEY]: authReducer,
  [MOCK_SERVER_KEY]: mockServerReducer,
};

export const store = configureStore({ reducer: reducers });

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export interface AppThunkConfig {
  dispatch: AppDispatch;
  state: RootState;
}
export const createAppStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    preloadedState,
    reducer: reducers,
  });
};
