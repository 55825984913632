import { rest } from 'msw';

import { getPredictionsSeed } from '../seed/predictions.seed';

const BACKEND_BASE_URL = process.env.REACT_APP_API_URL;

const isTestEnv = process.env.NODE_ENV === 'test';
const RESPONSE_DELAY_MS = isTestEnv ? 50 : 1500;

export const serverHandlers = [
  rest.get(`${BACKEND_BASE_URL}/fault/api/v1/predictions`, (req, res, ctx) => {
    const symptom = 'gearbox failure';

    return res(
      ctx.json(getPredictionsSeed(symptom)),
      ctx.delay(RESPONSE_DELAY_MS),
    );
  }),
  rest.post(`${BACKEND_BASE_URL}/fault/api/v1/predictions`, (req, res, ctx) => {
    const symptom = req.url.searchParams.get('faultDescription');
    if (symptom === null) {
      throw new Error('Symptom is needed for the predictions call.');
    }

    return res(
      ctx.json(getPredictionsSeed(symptom)),
      ctx.delay(RESPONSE_DELAY_MS),
    );
  }),
  rest.get(`${BACKEND_BASE_URL}/api-test`, (req, res, ctx) => {
    const query = req.url.searchParams.get('query');

    return res(
      ctx.json({
        query,
        foo: 'bar',
      }),
      ctx.delay(RESPONSE_DELAY_MS),
    );
  }),
  rest.post(`${BACKEND_BASE_URL}/api-test`, (req, res, ctx) => {
    const query = req.url.searchParams.get('query');
    const { test } = req.body as Record<string, string>;

    return res(
      ctx.json({
        query,
        test,
        foo: 'baz',
      }),
      ctx.delay(RESPONSE_DELAY_MS),
    );
  }),
];
