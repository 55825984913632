import { useEffect } from 'react';
import { SHELL_EVENTS, ShellSdk } from 'fsm-shell';
import { useAppDispatch } from './store/utils/hooks';
import { login } from './store/slices/auth.slice';
import { getServiceCallSummary } from './store/slices/serviceCall.slice';

const shellSdk = ShellSdk.init(window.parent, '*');

export interface FSMContext {
  account: string;
  company: string;
  accountId: string;
  companyId: string;
  userId: string;
  selectedLocale: string;
  cloudHost: string;
  auth: FSMAuth;
  viewState: {
    activityID: null;
    selectedActivityId: string;
    selectedBusinessPartnerId: string;
    selectedServiceCallId: string;
  };
}

interface FSMAuth {
  access_token: string;
  token_type: string;
  expires_in: number;
}

const useFSMContext = (): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handler = async (event: string) => {
      const context: FSMContext = JSON.parse(event);
      if (context) {
        dispatch(login(context));
        dispatch(getServiceCallSummary(context));
      }
    };
    shellSdk.on(SHELL_EVENTS.Version1.REQUIRE_CONTEXT, handler);
    shellSdk.emit(SHELL_EVENTS.Version1.REQUIRE_CONTEXT, {
      clientIdentifier: 'service-contract',
      auth: {
        response_type: 'token',
      },
    });

    return () => {
      shellSdk.off(SHELL_EVENTS.Version1.REQUIRE_CONTEXT, handler);
    };
  }, [dispatch]);
};

export default useFSMContext;
