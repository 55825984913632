import { PredictionResponse } from '../../integration/realTimePredictions.api';

const gearboxPredictions: PredictionResponse = {
  data: {
    predictions: [
      {
        rootCause: 'Shaft couplings need to be replaced due to misalignment',
        confidence: 0.85,
        predictedParts: [{ name: 'Bearings' }, { name: 'Shafts' }],
      },
      {
        rootCause: 'Replacement gears are required due to severe wind damage',
        confidence: 0.6541,
        predictedParts: [{ name: 'Couplings' }, { name: 'Lubricants' }],
      },
      {
        rootCause:
          'Cylindrical roller bearings, tapered roller bearings, due to failed or worn-out bearings',
        confidence: 0.4551,
        predictedParts: [{ name: 'Seals' }, { name: 'Gaskets' }],
      },
    ],
    faults: [{ description: 'gearbox failure' }],
  },
};

const generatorFailurePredictions: PredictionResponse = {
  data: {
    predictions: [
      {
        rootCause: 'Electrical Fault due to overheating',
        confidence: 0.79,
        predictedParts: [
          { name: 'Generator coils' },
          { name: 'Circuit breakers' },
        ],
      },
      {
        rootCause: 'Converter Failure caused by cooling system issues',
        confidence: 0.7451,
        predictedParts: [{ name: 'Capacitors' }, { name: 'Control board' }],
      },
      {
        rootCause: 'Converter Failure caused by a control system malfunction',
        confidence: 0.62,
        predictedParts: [{ name: 'Insulation coating' }],
      },
    ],
    faults: [{ description: 'generator failure' }],
  },
};

const bladeFailurePredictions: PredictionResponse = {
  data: {
    predictions: [
      {
        rootCause: 'Level Sensor - Humidity',
        confidence: 0.75,
        predictedParts: [{ name: 'Break Assembly' }],
      },
      {
        rootCause: 'Filter Failure - Salt in filter',
        confidence: 0.4,
        predictedParts: [],
      },
    ],
    faults: [{ description: 'blade failure' }],
  },
};

const electricalComponentFailurePredictions: PredictionResponse = {
  data: {
    predictions: [
      {
        rootCause: 'Power converter failure',
        confidence: 0.85,
        predictedParts: [{ name: 'Circuit breaker' }, { name: 'Fuse' }],
      },
      {
        rootCause: 'Inverter failure',
        confidence: 0.6541,
        predictedParts: [{ name: 'Ground fault protection unit' }],
      },
      {
        rootCause: 'Transformer failure',
        confidence: 0.4551,
        predictedParts: [{ name: 'Surge protection' }],
      },
    ],
    faults: [{ description: 'electrical component failure' }],
  },
};

export const getPredictionsSeed = (symptom: string): PredictionResponse => {
  if (symptom.match(/gearbox/i)) {
    return gearboxPredictions;
  }
  if (symptom.match(/generator/i)) {
    return generatorFailurePredictions;
  }
  if (symptom.match(/blade/i)) {
    return bladeFailurePredictions;
  }
  if (symptom.match(/electrical/i)) {
    return electricalComponentFailurePredictions;
  }

  return {
    data: {
      predictions: [],
      faults: [],
    },
  };
};
