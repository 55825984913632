import React from 'react';

import { useAppSelector } from '../../../store/utils/hooks';
import { FetchingStatus } from '../../../store/utils';
import { ReactComponent as Parts } from './assets/parts.svg';
import styles from './Predictions.module.scss';

const PREDICTIONS_TO_SHOW = 3;

const getConfidencePercentage = (confidence: number): string => {
  const percentage = confidence * 100;

  return percentage.toFixed(1);
};

const Predictions: React.FC = () => {
  const { data, realTimePredictionsStatus } = useAppSelector(
    (state) => state.predictions,
  );

  return (
    <ul className={styles.predictions}>
      {realTimePredictionsStatus === FetchingStatus.PENDING && 'Loading...'}
      {data !== null &&
        data.length === 0 &&
        'No prediction or prediction confidence is too low'}
      {data !== null &&
        data.length > 0 &&
        data
          .slice(0, PREDICTIONS_TO_SHOW)
          .map(({ rootCause, confidence, predictedParts }) => (
            <li key={rootCause} className={styles.prediction}>
              <div>{rootCause}</div>
              <div className={styles.confidence}>
                <div className={styles.progressContainer}>
                  <div
                    className={styles.progress}
                    style={{
                      transform: `translateX(-${100 - confidence * 100}%)`,
                      backgroundColor: getPredictionTextColor(confidence),
                    }}
                  />
                </div>
                <div>{getConfidencePercentage(confidence)}%</div>
              </div>
              {predictedParts.length > 0 && (
                <div className={styles.parts}>
                  <Parts className={styles.partsIcon} />
                  <span>
                    {predictedParts.map((part) => part.name).join(', ')}
                  </span>
                </div>
              )}
            </li>
          ))}
    </ul>
  );
};

const getPredictionTextColor = (confidence: number): string => {
  if (confidence < 0.4) {
    return '#FF5722';
  } else if (confidence < 0.7) {
    return '#FF8400';
  } else {
    return '#5FD855';
  }
};

export default Predictions;
