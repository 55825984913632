import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AppThunkConfig } from '../store';
import { worker } from '../../tests/mock-server/test-browser';
import { useAppSelector } from '../utils/hooks';
import { FetchingStatus } from '../utils';

export const MOCK_SERVER_KEY = 'mockServer';

export interface MockServerState {
  active: boolean;
  status: FetchingStatus;
}

const initialState: MockServerState = {
  active: false,
  status: FetchingStatus.IDLE,
};

export const toggleMockServer = createAsyncThunk<boolean, void, AppThunkConfig>(
  'toggleMockServer',
  async (_, { getState }) => {
    const {
      mockServer: { active },
    } = getState();
    if (active) {
      worker.stop();
      return false;
    } else {
      await worker.start();
      return true;
    }
  },
);

const mockServerSlice = createSlice({
  initialState,
  name: MOCK_SERVER_KEY,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(toggleMockServer.pending, (state) => {
        state.status = FetchingStatus.PENDING;
      })
      .addCase(toggleMockServer.fulfilled, (state, action) => {
        state.status = FetchingStatus.SUCCESS;
        state.active = action.payload;
      })
      .addCase(toggleMockServer.rejected, (state) => {
        state.status = FetchingStatus.ERROR;
      });
  },
});

export const useMockServerActive = (): boolean => {
  return useAppSelector((state) => state.mockServer.active);
};

const { reducer } = mockServerSlice;

export const mockServerReducer = reducer;
