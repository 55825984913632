import { setupWorker } from 'msw';
import { serverHandlers } from './server-handlers';

const isTestEnv = process.env.NODE_ENV === 'test';

export const worker = isTestEnv
  ? {
      stop: jest.fn(),
      start: jest.fn(),
    }
  : setupWorker(...serverHandlers);
