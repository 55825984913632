import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FSMContext } from '../../useFSMContext';
import { FetchingStatus } from '../utils';

interface JwtResponse {
  identityId: string;
  tenantId: number;
  token: string;
  data?: { identityId: string; tenantId: number; token: string };
}

export const AUTH_KEY = 'auth';

export interface AuthState {
  isAuthenticated: boolean;
  token: string | null;
  cluster: string | null;
  status: FetchingStatus;
  error: string | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  token: null,
  cluster: null,
  status: FetchingStatus.IDLE,
  error: null,
};

export const login = createAsyncThunk<
  { token: string; cluster: string },
  FSMContext
>('login', async (context) => {
  const cluster = context.cloudHost.slice(0, 2);
  const response: JwtResponse = await fetch(
    `${process.env.REACT_APP_API_URL}/auth/api/v1/token`,
    {
      method: 'POST',
      body: JSON.stringify({
        accountId: context.accountId,
        companyId: context.companyId,
        token: context.auth.access_token,
      }),
    },
  ).then((raw) => raw.json());
  const { token } = response.data ? response.data : response;

  return { token, cluster };
});

export const AuthSlice = createSlice({
  name: AUTH_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(login.pending, (state) => ({
        ...state,
        status: FetchingStatus.PENDING,
      }))
      .addCase(login.fulfilled, (state, action) => ({
        ...state,
        status: FetchingStatus.SUCCESS,
        isAuthenticated: true,
        token: action.payload.token,
        cluster: action.payload.cluster,
      }))
      .addCase(login.rejected, (state, action) => ({
        ...state,
        status: FetchingStatus.ERROR,
        error: action.error.message || 'Could not log in',
        isAuthenticated: false,
      })),
});

const { reducer } = AuthSlice;

export const authReducer = reducer;
