import React from 'react';

import {
  toggleMockServer,
  useMockServerActive,
} from '../../store/slices/mockServer.slice';
import { useAppDispatch } from '../../store/utils/hooks';
import { ReactComponent as RealData } from './assets/wifi.svg';
import { ReactComponent as MockData } from './assets/wifi-off.svg';
import styles from './MockServerToggle.module.scss';

const MockServerToggle: React.FC = () => {
  const isMocking = useMockServerActive();
  const dispatch = useAppDispatch();

  return (
    <button
      data-testid="toggle-mock-server"
      onClick={() => dispatch(toggleMockServer())}
      className={styles.toggle}
    >
      {isMocking ? <MockData /> : <RealData />}
    </button>
  );
};

export default MockServerToggle;
