import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Provider } from 'react-redux';

import { store } from './store/store';
import PluginContainer from './components/PluginContainer/PluginContainer';
import './App.scss';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <CssBaseline />
      <PluginContainer />
    </Provider>
  );
};

export default App;
