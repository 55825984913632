import React from 'react';

import styles from './Summary.module.scss';
import { useAppSelector } from '../../../store/utils/hooks';
import { FetchingStatus } from '../../../store/utils';

const Summary: React.FC = () => {
  const { summary, status } = useAppSelector((state) => state.serviceCall);
  const cluster = useAppSelector((state) => state.auth.cluster);
  const dataReady = summary !== null && status === FetchingStatus.SUCCESS;

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.label}>Contact Person&apos;s Email</div>
        <div className={styles.value}>
          {dataReady && <>{summary.contact || 'N/A'}</>}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Recently Serviced By</div>
        {dataReady && (
          <>
            {summary.suggestedTechnicians.length > 0 ? (
              <ul className={styles.listValue}>
                {summary.suggestedTechnicians.map((technician) => (
                  <li key={technician.id}>
                    {technician.firstName} {technician.lastName}
                  </li>
                ))}
              </ul>
            ) : (
              <div className={styles.value}>N/A</div>
            )}
          </>
        )}
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Last Jobs for This Customer</div>
        {dataReady && (
          <>
            {summary.lastJobs.length > 0 ? (
              <ul className={styles.listValue}>
                {summary.lastJobs.map((job) => (
                  <li key={job.id}>
                    {job.subject}, {job.typeName && `${job.typeName}, `}
                    <a
                      target="_blank"
                      href={`https://${cluster}.coresystems.net/shell/#/planning-dispatching/service-calls/detail?~id=${job.id}&~code=${job.code}`}
                      rel="noreferrer"
                    >
                      {job.code}
                    </a>
                  </li>
                ))}
              </ul>
            ) : (
              'N/A'
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Summary;
