import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AppThunkConfig } from '../store';
import {
  fetchRealTimePredictions,
  Prediction,
  PredictionList,
  regenerateRealTimePredictions,
} from '../../integration/realTimePredictions.api';
import { FetchingStatus } from '../utils';

export const PREDICTIONS_KEY = 'predictions';

export interface PredictionsState {
  initialPredictionsStatus: FetchingStatus;
  realTimePredictionsStatus: FetchingStatus;
  error: string | null;
  data: Prediction[] | null;
  subject: string | null;
}

const initialState: PredictionsState = {
  initialPredictionsStatus: FetchingStatus.IDLE,
  realTimePredictionsStatus: FetchingStatus.IDLE,
  error: null,
  data: null,
  subject: null,
};

export interface PredictionsWithSubject extends PredictionList {
  subject: string | null;
}

export const getPredictions = createAsyncThunk<
  PredictionsWithSubject,
  void,
  AppThunkConfig
>('getPredictions', async (_, { getState, signal }) => {
  const {
    auth: { token },
    serviceCall: { keyValues },
  } = getState();
  if (keyValues === null) {
    throw new Error('Key service call values must not be null!');
  }

  const { predictions, faults } = await fetchRealTimePredictions({
    symptom: keyValues.subject,
    jobId: keyValues.serviceCallId,
    equipmentId: keyValues.equipmentId,
    token,
    signal,
  });

  return {
    predictions,
    subject: faults[0]?.description || null,
  };
});

export const regeneratePredictions = createAsyncThunk<
  PredictionList,
  string,
  AppThunkConfig
>('regeneratePredictions', async (symptom, { getState, signal }) => {
  const {
    auth: { token },
    serviceCall: { keyValues },
  } = getState();
  if (keyValues === null) {
    throw new Error('Key service call values must not be null!');
  }

  const { predictions } = await regenerateRealTimePredictions({
    signal,
    symptom,
    token,
    jobId: keyValues.serviceCallId,
  });

  return { predictions };
});

const predictionsSlice = createSlice({
  name: PREDICTIONS_KEY,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getPredictions.pending, (state) => {
        state.initialPredictionsStatus = FetchingStatus.PENDING;
        state.data = null;
      })
      .addCase(getPredictions.fulfilled, (state, action) => {
        state.initialPredictionsStatus = FetchingStatus.SUCCESS;
        state.data = action.payload.predictions;
        state.subject = action.payload.subject;
      })
      .addCase(getPredictions.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          state.initialPredictionsStatus = FetchingStatus.IDLE;
          return;
        }
        state.initialPredictionsStatus = FetchingStatus.ERROR;
        state.error = action.error.message || 'Something went wrong.';
        state.data = null;
      })
      .addCase(regeneratePredictions.pending, (state) => {
        state.realTimePredictionsStatus = FetchingStatus.PENDING;
        state.data = null;
      })
      .addCase(regeneratePredictions.fulfilled, (state, action) => {
        state.realTimePredictionsStatus = FetchingStatus.SUCCESS;
        state.data = action.payload.predictions;
      })
      .addCase(regeneratePredictions.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          state.realTimePredictionsStatus = FetchingStatus.IDLE;
          return;
        }
        state.realTimePredictionsStatus = FetchingStatus.ERROR;
        state.error = action.error.message || 'Something went wrong.';
        state.data = null;
      });
  },
});

const { reducer } = predictionsSlice;

export const predictionsReducer = reducer;
