import React from 'react';

import Summary from './Summary/Summary';
import SymptomSearch from './SymptomSearch/SymptomSearch';
import Predictions from './Predictions/Predictions';
import useFSMContext from '../../useFSMContext';
import MockServerToggle from '../MockServerToggle/MockServerToggle';
import styles from './PluginContainer.module.scss';

const PluginContainer: React.FC = () => {
  const isProduction = process.env.REACT_APP_ENVIRONMENT_NAME === 'production';
  useFSMContext();

  return (
    <>
      <div className={styles.titles}>
        <h2>Input</h2>
        <div className={styles.symptomSearchTitle}>
          <h2>Symptoms and Observations</h2>
          {!isProduction && <MockServerToggle />}
        </div>
        <h2>Predictions</h2>
      </div>
      <div className={styles.container}>
        <Summary />
        <SymptomSearch />
        <Predictions />
      </div>
    </>
  );
};

export default PluginContainer;
