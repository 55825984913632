import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunkConfig } from '../store';
import { FSMContext } from '../../useFSMContext';
import { FetchingStatus } from '../utils';
import {
  fetchServiceCall,
  fetchServiceCallSummary,
  ServiceCallSummary,
} from '../../integration/serviceCall.api';

export const SERVICE_CALL_KEY = 'serviceCall';

interface KeyServiceCallValues {
  subject: string;
  serviceCallId: string;
  equipmentId: string;
}

export interface ServiceCallState {
  status: FetchingStatus;
  error: string | null;
  summary: ServiceCallSummary | null;
  keyValues: KeyServiceCallValues | null;
}

const initialState: ServiceCallState = {
  status: FetchingStatus.IDLE,
  error: null,
  summary: null,
  keyValues: null,
};

export const getServiceCallSummary = createAsyncThunk<
  ServiceCallSummary | null,
  FSMContext,
  AppThunkConfig
>('getServiceCallSummary', async (context, { dispatch, signal }) => {
  const {
    cloudHost,
    account,
    company,
    viewState: { selectedServiceCallId },
  } = context;

  const serviceCall = await fetchServiceCall({
    cloudHost,
    account,
    company,
    signal,
    serviceCallId: selectedServiceCallId,
    token: context.auth.access_token,
  });
  if (serviceCall.equipments.length === 0) {
    return null;
  }

  dispatch(
    setKeyValues({
      serviceCallId: serviceCall.id,
      subject: serviceCall.subject,
      equipmentId: serviceCall.equipments[0],
    }),
  );

  return fetchServiceCallSummary({
    cloudHost,
    account,
    company,
    signal,
    serviceCall,
    token: context.auth.access_token,
  });
});

const serviceCallSlice = createSlice({
  name: SERVICE_CALL_KEY,
  initialState,
  reducers: {
    setKeyValues(state, action: PayloadAction<KeyServiceCallValues>) {
      return {
        ...state,
        keyValues: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getServiceCallSummary.pending, (state) => {
        state.status = FetchingStatus.PENDING;
      })
      .addCase(getServiceCallSummary.fulfilled, (state, action) => {
        state.status = FetchingStatus.SUCCESS;
        state.summary = action.payload;
      })
      .addCase(getServiceCallSummary.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.status = FetchingStatus.ERROR;
        state.error = action.error.message || 'Something went wrong.';
        state.summary = null;
        state.keyValues = null;
      });
  },
});

const { actions, reducer } = serviceCallSlice;

const { setKeyValues } = actions;

export const serviceCallReducer = reducer;
