import { isDefined } from '../store/utils';

export const BASE_API_URL = process.env.REACT_APP_API_URL;

export interface ApiFetchParams {
  token: string | null;
  signal: AbortSignal;
}

export interface ApiResponse<T> {
  data: T;
}

type QueryParams = Record<
  string,
  string | number | boolean | string[] | null | undefined
>;

export const buildUrl = (
  path: string,
  params?: QueryParams,
  baseUrl?: string,
): string => {
  const urlWithPath = `${baseUrl || BASE_API_URL}${path}`;
  if (!isDefined(params)) {
    return urlWithPath;
  }
  const queryString = Object.entries(params)
    .filter(([, value]) => isDefined(value))
    .map(([key, value]) => `${key}=${encodeURIComponent(String(value))}`)
    .join('&');

  if (queryString === '') {
    return urlWithPath;
  } else {
    return `${urlWithPath}?${queryString}`;
  }
};

interface RequestParams {
  path: string;
  token: string | null;
  queryParams?: QueryParams;
  config?: RequestInit;
  baseUrl?: string;
  bearerAuthScheme?: boolean;
  retry?: number;
}

interface PostRequestParams<B> extends RequestParams {
  body?: B;
}

/**
 * A wrapper around fetch API.
 */
class Api {
  static getDefaultHeaders(token: string | null, bearer = false): HeadersInit {
    if (token === null) {
      throw new Error('Not authenticated!');
    }

    return {
      'Content-Type': 'application/json',
      Authorization: bearer ? `bearer ${token}` : token,
    };
  }

  async get<T>({
    path,
    token,
    queryParams,
    config,
    baseUrl,
    bearerAuthScheme,
    retry,
  }: RequestParams): Promise<T> {
    const urlWithQuery = buildUrl(path, queryParams, baseUrl);

    if (!isDefined(retry)) {
      return fetch(urlWithQuery, {
        ...config,
        headers: {
          ...Api.getDefaultHeaders(token, bearerAuthScheme),
          ...config?.headers,
        },
      }).then((res) => res.json());
    } else {
      let retryCount = 0;
      while (retryCount < retry) {
        try {
          return await fetch(urlWithQuery, {
            ...config,
            headers: {
              ...Api.getDefaultHeaders(token, bearerAuthScheme),
              ...config?.headers,
            },
          }).then((res) => res.json());
        } catch (e) {
          retryCount++;
        }
      }
      throw new Error('Request timed out!');
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async post<T, B = any>({
    path,
    token,
    queryParams,
    body,
    config,
    baseUrl,
    bearerAuthScheme,
    retry,
  }: PostRequestParams<B>): Promise<T> {
    const urlWithQuery = buildUrl(path, queryParams, baseUrl);

    if (!isDefined(retry)) {
      return fetch(urlWithQuery, {
        ...config,
        ...(isDefined(body) ? { body: JSON.stringify(body) } : {}),
        method: 'POST',
        headers: {
          ...Api.getDefaultHeaders(token, bearerAuthScheme),
          ...config?.headers,
        },
      }).then((res) => res.json());
    } else {
      let retryCount = 0;
      while (retryCount < retry) {
        try {
          return await fetch(urlWithQuery, {
            ...config,
            ...(isDefined(body) ? { body: JSON.stringify(body) } : {}),
            method: 'POST',
            headers: {
              ...Api.getDefaultHeaders(token, bearerAuthScheme),
              ...config?.headers,
            },
          }).then((res) => res.json());
        } catch (e) {
          retryCount++;
        }
      }
      throw new Error('Request timed out!');
    }
  }
}

export default new Api();
