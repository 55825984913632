import api, { ApiFetchParams, ApiResponse } from './api';

const FSM_HEADERS = {
  'X-Client-Version': 'COR_FSM_WORKFORCE_MANAGEMENT',
  'X-Client-Id': 'COR_FSM_PORTAL',
};

export interface ServiceCall {
  id: string;
  subject: string;
  businessPartner: string | null;
  contact: string | null;
  typeName: string | null;
  code: string | null;
  equipments: string[];
}

export interface Activity {
  id: string;
  responsibles: string[];
}

export interface Customer {
  name: string | null;
}

export interface Contact {
  emailAddress: string | null;
}

export interface Technician {
  id: string;
  firstName: string | null;
  lastName: string | null;
  emailAddress: string | null;
}

export type ServiceCallResponse = ApiResponse<
  Array<{ serviceCall: ServiceCall }>
>;

type ActivityResponse = ApiResponse<Array<{ activity: Activity }>>;

type TechnicianResponse = ApiResponse<Array<{ technician: Technician }>>;

export interface ServiceCallSummary {
  contact: string | null;
  suggestedTechnicians: Technician[];
  lastJobs: ServiceCall[];
}

export interface BaseServiceCallParams extends ApiFetchParams {
  cloudHost: string;
  account: string;
  company: string;
}

export interface FetchServiceCallParams extends BaseServiceCallParams {
  serviceCallId: string;
}

export interface FetchServiceCallSummaryParams extends BaseServiceCallParams {
  serviceCall: ServiceCall;
}

export const fetchServiceCall = async (params: FetchServiceCallParams) => {
  const { cloudHost, account, company, token, signal, serviceCallId } = params;

  const serviceCallResponse = await api.get<ServiceCallResponse>({
    token,
    baseUrl: `https://${cloudHost}`,
    path: `/api/data/v4/ServiceCall/${serviceCallId}`,
    bearerAuthScheme: true,
    queryParams: {
      account,
      company,
      dtos: 'ServiceCall.25',
    },
    config: {
      signal,
      headers: FSM_HEADERS,
    },
  });

  return serviceCallResponse.data[0].serviceCall;
};

export const fetchServiceCallSummary = async (
  params: FetchServiceCallSummaryParams,
): Promise<ServiceCallSummary> => {
  const [contact, lastJobs, suggestedTechnicians] = await Promise.all([
    fetchContact(params),
    fetchLastJobs(params),
    fetchSuggestedTechnicians(params),
  ]);

  return {
    contact,
    lastJobs,
    suggestedTechnicians,
  };
};

const fetchContact = async (
  params: FetchServiceCallSummaryParams,
): Promise<string | null> => {
  const { cloudHost, account, company, token, signal, serviceCall } = params;
  if (serviceCall.contact === null) {
    return null;
  }

  const contact = await api.get<Contact>({
    token,
    baseUrl: `https://${cloudHost}`,
    path: `/api/data/v4/Contact/${serviceCall.contact}`,
    bearerAuthScheme: true,
    queryParams: {
      account,
      company,
      dtos: 'Contact.17',
    },
    config: {
      signal,
      headers: FSM_HEADERS,
    },
  });

  return contact.emailAddress;
};

const fetchLastJobs = async (
  params: FetchServiceCallSummaryParams,
): Promise<ServiceCall[]> => {
  const { cloudHost, account, company, token, signal, serviceCall } = params;
  if (serviceCall.businessPartner === null) {
    return [];
  }

  const allJobs = await api.post<ServiceCallResponse>({
    token,
    baseUrl: `https://${cloudHost}`,
    path: '/api/query/v1',
    bearerAuthScheme: true,
    queryParams: {
      account,
      company,
      dtos: 'ServiceCall.25',
    },
    config: {
      signal,
      headers: FSM_HEADERS,
    },
    body: {
      query: `SELECT serviceCall.id, serviceCall.subject, serviceCall.typeName, serviceCall.code, serviceCall.createDateTime, serviceCall.equipments FROM ServiceCall serviceCall WHERE serviceCall.businessPartner = '${serviceCall.businessPartner}' ORDER BY serviceCall.createDateTime DESC LIMIT 4`,
    },
  });

  // We ignore the first element, because that is the current job
  return allJobs.data.slice(1, 4).map((job) => job.serviceCall);
};

const fetchSuggestedTechnicians = async (
  params: FetchServiceCallSummaryParams,
): Promise<Technician[]> => {
  const { cloudHost, account, company, token, signal, serviceCall } = params;
  const equipmentId = serviceCall.equipments[0];

  const relatedJobs = await api.post<ServiceCallResponse>({
    token,
    baseUrl: `https://${cloudHost}`,
    path: '/api/query/v1',
    bearerAuthScheme: true,
    queryParams: {
      account,
      company,
      dtos: 'ServiceCall.25',
    },
    config: {
      signal,
      headers: FSM_HEADERS,
    },
    body: {
      query: `SELECT serviceCall.id, serviceCall.createDateTime FROM ServiceCall serviceCall WHERE '${equipmentId}' = ANY(serviceCall.equipments) ORDER BY serviceCall.createDateTime DESC LIMIT 4`,
    },
  });
  if (relatedJobs.data.length === 0) {
    return [];
  }

  const relatedJobIds = relatedJobs.data
    .map((job) => `'${job.serviceCall.id}'`)
    .join(',');
  const relatedActivities = await api.post<ActivityResponse>({
    token,
    baseUrl: `https://${cloudHost}`,
    path: '/api/query/v1',
    bearerAuthScheme: true,
    queryParams: {
      account,
      company,
      dtos: 'Activity.25',
    },
    config: {
      signal,
      headers: FSM_HEADERS,
    },
    body: {
      query: `SELECT activity.id, activity.responsibles FROM Activity activity WHERE activity.object IN (${relatedJobIds}) ORDER BY activity.createDateTime DESC`,
    },
  });
  const technicianIds = relatedActivities.data
    .reduce((ids: string[], item) => {
      return [...ids, ...item.activity.responsibles];
    }, [])
    .map((id) => `'${id}'`)
    .join(',');
  if (technicianIds === '') {
    return [];
  }

  const suggestedTechnicians = await api.post<TechnicianResponse>({
    token,
    baseUrl: `https://${cloudHost}`,
    path: '/api/query/v1',
    bearerAuthScheme: true,
    queryParams: {
      account,
      company,
      dtos: 'Person.25',
    },
    config: {
      signal,
      headers: FSM_HEADERS,
    },
    body: {
      query: `SELECT technician.id, technician.firstName, technician.lastName, technician.emailAddress FROM Person technician WHERE technician.id IN (${technicianIds})`,
    },
  });

  return suggestedTechnicians.data.map((st) => st.technician).slice(0, 3);
};
